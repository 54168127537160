:root {
    --main: #07243d; 
    --white: whitesmoke;
}

.comparePage {
    //margin-top: 200px;
    text-align: center;
    justify-content: center;

    .header {
        //position: absolute;
        //top: 0px;
        height: 150px;
        width: 100%;
        text-align: center;
        background-color: var(--main);
        border-style: solid;
    
        .logo {
            width: 350px;
            margin-top: 1.1em;
        }
    }

    .CompareUi {

        .matrixCompareHeader {
            text-align: left !important;
            font-size: 24px;
            font-style: italic;
            font-weight: normal;
            margin: 40px 25px 25px 25px;
            border-bottom: 2px solid var(--main);
        }

        .nocAndSocCodeLabelsDiv {
            margin: 0 25px;
            text-align: left !important;
            white-space: nowrap;

            p {
                margin-bottom: 0 !important;
            }

            .nocCodeLabelDiv {
                width: 250px;
                display: inline-block;
            }

            .socCodeLabelDiv {
                width: 250px;
                display: inline-block;
            }
        }

        .switchNocCodeParentDiv {
            text-align: left;
            margin-top: 25px;

            .switchNocCodeParentHeader {
                margin-left: 25px;
            }

            .switchNocCodeDiv {
                text-align: left;
    
                .inputAndButtonDiv {
                    margin: 0 25px;
                    display: flex;
                    align-items: baseline;
                    margin-top: -2.5em;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5em 1em;

                    * {
                        display: inline-block;
                        height: 30px;
                    }
        
                    #nocCodeInputField, #nocCodeToCompareInputField {
                        width: 5em;
                    }

                    // .inputFieldDiv {
                    //     // margin-top: 2px;
                    //     // display: inline;
                    //     width: 100%;
                    //     max-width: 350px;
        
                    //     img {
                    //         position: absolute;
                    //         margin: 10px 6px;
                    //         width: 20px;
                    //         height: 20px;   
                    //     }
            
                    //     input {
                    //         font-size: 18px;
                    //         border: 1px solid var(--white);
                    //         border-color: var(--main);
                    //         height: 30px;
                    //         text-indent: 30px;
                    //         outline: none;
                    //         margin: 5px 1px 20px 0px;
                    //         width: 100%;
                    //     }
                        
                    //     // input field focus when clicked
                    //     input:focus {
                    //         box-shadow: 0px 0px 10px var(--main);
                    //         border: 1.5px solid var(--main);
                    //     }
                    // }
                    
                    .buttonDiv {
                        // display: inline;
                        text-align: center;
                        margin-top: 25px;
                        // margin-left: 0.5em;
            
                        // need this to overwrite mains font.
                        button, span {
                            color: var(--white);
                        }
        
                        button {
                            font-size: 18px;
                            border-color: var(--main);
                            background-color: var(--main);
                            color: var(--white);
                            text-align: center;
                            border: none;
                            padding: 0px;
                            width: 125px;
                            height: 30px;
                            // margin-left: 5px;
                            // transition: all 0.5s;
                            cursor: pointer;
                            box-shadow: none !important;
                            margin-right: 0.5em;
                        }    
                        
                        /* Styling for button animation and hover symbol */
                        button {
                            cursor: pointer;
                            display: inline-block;
                            position: relative;
                            transition: 0.5s;
                        }
                          
                        // button:after {
                        //     content: '»';
                        //     position: absolute;
                        //     opacity: 0;  
                        //     top: 1px;
                        //     right: -20px;
                        //     transition: 0.5s;
                        // }
                          
                        // button:hover{
                        //     padding-right: 20px;
                        //     padding-left: 10px;
                        // }
                          
                        // button:hover:after {
                        //     opacity: 1;
                        //     right: 10px;
                        // }
                    }
                }
            }
        }

        .legendDiv {
            margin: -45px 25px -10px 25px;
            text-align: left;

            * {
                font-size: 0.75em;
                font-weight: bold;
                margin-bottom: 0px;
            }
        }

        .notesDiv {
            text-align: left;
            margin: 15px 25px;

            .accordion-header {
            
                button {
                    margin-top: 0;
                    background-color: var(--main);
                    color: var(--white);
                    box-shadow: none;

                    span {
                        color: var(--white);
                    }
                }
            }

            a {
                color: var(--main);
            }

            a:hover, a:active {
                color: blue;
            }
        }
        
        .utilityDiv {
            margin: 0 25px 5px 25px;
            width: auto;
            display:flex;
            justify-content:space-between;
            align-items: flex-end;
        }

        .renderCompareTableDiv .react-bootstrap-table {
            margin: 0 25px;
            display: block;
            height: auto !important;
            min-height: 630px;
            box-shadow: 2px 2px 7px var(--main);
    
            table {
                width: 100%;
                // display: block;
                overflow-x: auto;
                overflow-y: auto;
                white-space: nowrap;
                margin-right: 10px;
                // margin-bottom: 100px;
                table-layout: fixed;
                height: 100%;
            }

            th {
                font-size: 16px;
                text-align: center;
                text-transform: lowercase; 
                position: sticky;
                top: 0; /* Don't forget this, required for the stickiness */
                background-color: var(--main);
                border-color: var(--main);
                // border-style: solid;
                box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
                height: 40px;
                // margin: 0;
                padding-left: 10px;
                padding-right: 10px;
                white-space: normal;
            }

            td {
                border: 1px solid black;
                // white-space: nowrap;
                white-space: normal;
                word-wrap: break-word;
                font-size: 16px;
                text-align: left;
                width: auto;
                min-width: 50px;
                max-width: 1000px;
                height: 50px;
                overflow: auto;
                padding-left: 10px;
                padding-right: 10px;
            }
    
            div {
                display: inline-block;
            }
    
            tbody tr:nth-child(odd) {
                background-color: rgb(220, 220, 220);
            }
    
            // *very important, overrides styling from ResultPage that are picked up from other components
            thead tr {
                border: 0 !important;
            }
    
            // *very important, overrides styling from ResultPage that are picked up from other components
            @media only screen and (max-width: 1050px) {
                .table thead tr th:nth-child(3),
                .table tbody tr td:nth-child(3) {
                    width: auto !important;
                }
    
                .table thead tr th:nth-child(4),
                .table tbody tr td:nth-child(4) {
                    display: table-cell !important;
                }
            }
        }

        // pagination under table
        .paginationDiv {
            margin-top: 15px;

            span {
                display: inline;
                font-size: 20px;
                color: var(--main);
            }

            ul {
                justify-content: center;

                li {
                    display: inline;
                    
                    a {
                        background-color: var(--main);
                        color: var(--white)
                    }
                }
            }
        }
    }
}

// tool-tip css must be declared outside of component, this is because the tooltip appears @ root level. 
.tooltip-inner {
    background-color: var(--white);
    box-shadow: 0px 0px 4px black;
    opacity: 1 !important;
    text-align: left;
    max-width: 50%;
    min-width: 250px;
    font-size: 16px;
}

.tooltip.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: var(--main);
}

.tooltip.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: var(--main);
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: var(--main);
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: var(--main);
}