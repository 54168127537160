:root {
    --main: #07243d; 
    --white: whitesmoke;
}

.loadingPage {
    background-color: var(--main);
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    text-align: center;
    
    // spinning and blurring infinitely
    .image {
        margin-top: 350px;
        -webkit-animation: spin 4s linear infinite, flicker 2s infinite;
        -moz-animation: spin 4s linear infinite, flicker 2s infinite;
        animation: spin 4s linear infinite, flicker 2s infinite;
    }

    // rotate element 360 degrees
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

    // flicker element opacity
    @-webkit-keyframes flicker {
        0%   { opacity: 1; }
        100% { opacity: 0.50; }
    }
    @-moz-keyframes flicker {
        0%   { opacity: 1; }
        100% { opacity: 0.50; }
    }
    @keyframes flicker {
        0%   { opacity: 1; }
        100% { opacity: 0.50; }
    }

    .text {
        color: var(--white);
        margin-top: 25px;
    }
}