:root {
    --main: #07243d;
    --white: whitesmoke;
}

.navBar {
  border-bottom: medium solid;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);

  .navbar-text {
    color: black;
  }

  button { // need to override default AUTONOC button style - it's grabbing them from ResultPage.scss somehow..
    margin: 0 0 0 1em !important;
    height: 2.5em !important;
    width: 6em !important;
    box-shadow: unset !important;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 0.5em;
  }

  button:hover {
    text-decoration: unset !important;
    color: var(--bs-btn-hover-color);
    background-color: var(--main) !important;
    border-color: var(--main) !important;
  }

  .btn-outline-success-autonoc {
    --bs-btn-color: var(--main);
    --bs-btn-border-color: var(--main);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--main);
    --bs-btn-hover-border-color: var(--main);
    --bs-btn-focus-shadow-rgb: 25, 135, 84;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--main);
    --bs-btn-active-border-color: var(--main);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--main);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--main);
    --bs-gradient: none;
  }

  .nav-item .disabled {
    opacity: 50% !important;
  }
}