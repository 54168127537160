:root {
    --main: #07243d;
    --white: whitesmoke;
}

.notAllowedPage {
  text-align: center;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  margin: 0 5em;

  p {
    color: var(--white) !important;
  }
}
