/* Doing this for App.js here due to multiple page layout */
// Changing style of website when it's a big enough size.
@media only screen and (min-width: 1100px) {
    .app {
      display: grid;
      grid-template-columns: 42% auto;
    }
}

/* Globals */
:root {
   //--main: #282C34;
   --main: #07243d; 
   --white: whitesmoke;
   //--white: #FFFFFF;
}

/* all elements */
* {
    box-sizing: border-box;
    color: var(--main);
}


/* input-page */
// Details.js
.leftSide {
    //position: absolute;
    //top: 0px;
    //height: 475px;
    width: 100%;

    text-align: center;
    background-color: var(--main);
    padding: 1em;
    box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, 0.15);

    .logo {
        margin-top: 1em;
        width: 350px;
    }

    h1 {
        color: var(--white);
        font-size: 16px;
    }

    .line {
        // border-top: 3px solid rgba(245, 245, 245, 0.466);
        border-top: 1px solid var(--white);
        margin: 3em auto 0 auto;
        width: 450px;
    }

    .detailsDiv {
        text-align: center;
        margin: 0.0em 2em;
        
        .detailsInfo {
            line-height: 1.8rem;
            margin: 2.0em 2.0em;
            color: whitesmoke;
        }
    }

    .copyrightLeftside {
        display: none;
    }
}

// InputUI.js
.rightSide {
    //position: absolute;
    //top: 475px;
    //bottom: 0px;
    width: 100%;
    text-align: left;
    background-color: var(--white);
    padding: 0em;

    // current page links
    .navLinksDiv {
        margin: 0px auto;
        padding: 0;
        box-sizing: border-box;
        font-size: 16px;

        .arrow {
            margin: 0;
        }

        * {
            display: inline-block;
            margin: 5px;
        }

        // can use this for any inactive link
        .inactiveLink {
            pointer-events: none;
            cursor: default;
            text-decoration: none;
            color: black;
        }
    }

    // header for ui
    .enenocHeader {
        font-size: 24px;
        font-style: italic;
        font-weight: normal;
        margin: 40px 25px 25px 25px;
        border-bottom: 2px solid var(--main);
    }

    // user interface components
    .inputUIForm {
        margin: 0 25px;

        label {
            margin-bottom: 0px;
        }

        // div that allows the input and button to be on same line
        .inputAndButtonDiv {
            .inputFieldDiv {
                margin-top: 2px;
                display: inline;
    
                img {
                    position: absolute;
                    margin: 10px 6px;
                    width: 20px;
                    height: 20px;   
                }
    
                input {
                    font-size: 18px;
                    border: 1px solid var(--white);
                    border-color: var(--main);
                    // width: calc(100% - 5.9em);
                    width: 100%;
                    height: 30px;
                    text-indent: 30px;
                    outline: none;
                    margin: 5px 1px 20px 0px;
                }
                
                // input field focus when clicked
                input:focus {
                    box-shadow: 0px 0px 10px var(--main);
                    border: 1.5px solid var(--main);
                }
            }
            
            .buttonDiv {
                // display: inline;
                text-align: center;
                margin-top: 25px;
    
                // need this to overwrite mains font.
                button, span {
                    color: var(--white);
                }

                button {
                    font-size: 18px;
                    border-color: var(--main);
                    background-color: var(--main);
                    color: var(--white);
                    text-align: center;
                    border: none;
                    padding: 0px;
                    width: 100px;
                    height: 30px;
                    // margin-left: 5px;
                    transition: all 0.5s;
                    cursor: pointer;
                    box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
                }    
                
                /* Styling for button animation and hover symbol */
                button {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    transition: 0.5s;
                }
                  
                button:after {
                    content: '»';
                    position: absolute;
                    opacity: 0;  
                    top: 1px;
                    right: -20px;
                    transition: 0.5s;
                }
                  
                button:hover{
                    padding-right: 20px;
                    padding-left: 10px;
                }
                  
                button:hover:after {
                    opacity: 1;
                    right: 10px;
                }
            }
        }  
    }

    // info section
    .infoHeader {
        text-align: left;
        //margin: 0px 25px;
        font-size: 24px;
        font-style: italic;
        font-weight: normal;
        margin: 40px 25px 5px 25px;
        border-bottom: 2px solid var(--main);
        display: inline-block;
        width: auto;
    }

    .info {
        margin: auto 25px;
    }

    #spinnerDiv {
        margin: 50px auto;
        display: block;
        text-align: center;

        #theSpinner {
            color: var(--white);    // setting the same as background until submission is clicked
        }
    }

    .copyrightRightsideDiv {
        margin-top: 0px;
        position: relative;
        text-align: center;
        justify-content: center;
        background-color: var(--main);
        
        .copyrightRightside {
            color: var(--white);
            font-size: 14px;
        }
    }
    
}



/* Section for changing specific things depending on width size */
// *code it so that smaller widths get executed first

// extreme case where screen size is very small so fitting logo and texts
@media only screen and (max-width: 400px) {
    // fixing logo and details info
    .leftSide {
        height: 600px !important;

        .logo {
            max-width: 100% !important;
        }
    
        .detailsInfo {
            word-wrap: break-word !important;
            margin: 2.0em 0em !important;
        }
    }

    .rightSide {
        top: 600px !important;
    }
}

@media only screen and (max-width: 500px) {
    .leftSide {
        height: 550px;
    }

    .rightSide {
        top: 550px;
    }
}

@media only screen and (max-width: 550px) {
    .leftSide {
        .line {
            width: auto;
        }

        .detailsDiv {
            .detailsInfo {
                width: auto;
                margin: 2.0em 0em;
            }
        }
    }
}

// changing navigation link title depending on size
@media only screen and (max-width: 626px) {
    .show-mobile-title {
        display: inline block !important;
    }
    .show-regular-title {
        display: none !important;
    }
}

// changing nav title to fullname when it fits
@media only screen and (min-width: 627px) {
    .rightSide {
        .show-mobile-title {
            display: none !important;
        }
        .show-regular-title {
            display: inline block !important;
        }

        .enenocHeader, .inputUIForm, .infoHeader, .info {
            margin-right: 75px !important;
            margin-left: 75px !important;
        }
    }
}

// fixing UI margins when screen gets bigger
@media only screen and (min-width: 800px) {
    .rightSide {
        .enenocHeader, .inputUIForm, .infoHeader, .info {
            margin-right: 100px !important;
            margin-left: 100px !important;
        }
    }

    .leftSide {
        .detailsDiv .detailsInfo {
            margin: 2.5em 5.0em;
        }
    }
}

// fixing UI margins when screen gets bigger
@media only screen and (min-width: 1000px) {
    .leftSide {
        .detailsDiv .detailsInfo {
            margin: 2.5em 8.0em;
        }
    }
    
    .rightSide {
        .enenocHeader, .inputUIForm, .infoHeader, .info {
            margin-right: 130px !important;
            margin-left: 130px !important;
        }
    }
}


/* Styling for bigger webpage sizes */

// re-styling from mobile --> desktop
@media only screen and (min-width: 1100px) {
    .leftSide {
        position: relative;
        height: 100%;

        .logo {
             margin-top: 4em;
             width: 350px;
         }
    
        .line {
            width: 400px;
        }

        .detailsDiv {
            margin: 2.5em 0.5em 0em 0.5em;
            
            .detailsInfo {
                line-height: 2.0rem;
                margin: 2.5em
            }
        }

        .copyrightLeftside {
            font-size: 14px;
            text-align: center;
            color: var(--white);
            background-color: #07243d;
            margin: 0 auto;
            bottom: 0;
            display: block;
            margin-top: 450px;
            position: relative;
        }
    }

    .rightSide {
        position: relative;
        height: 100vh;
        margin-top: 0px;
        top: 0px;

        /* have to reset margins back to normal */
        .enenocHeader {
            margin: 250px 50px 25px 25px !important;
        }

        .inputUIForm {
            margin: 0 25px !important;
        }

        .infoHeader {
            margin: 40px 25px 5px 25px !important;
        }

        .info {
            margin: auto 50px auto 25px !important;
        }

        #spinnerDiv {
            margin: 125px auto;
        }

        //hiding copyright on right side cause we have left side now
        .copyrightRightsideDiv {
            display: none;
        }
    }
}

// fixing UI margins when screen gets bigger
@media only screen and (min-width: 1250px) {
    .leftSide {
        .line {
            width: 450px;
        }
    }

    .rightSide {
        .enenocHeader, .inputUIForm, .infoHeader, .info {
            margin-right: 75px !important;
            margin-left: 75px !important;
        }
    }
}

// fixing UI margins when screen gets bigger
@media only screen and (min-width: 1500px) {
    .rightSide {
        .enenocHeader, .inputUIForm, .infoHeader, .info {
            margin-right: 125px !important;
            margin-left: 125px !important;
        }
    }
}

// fixing UI margins when screen gets bigger
@media only screen and (min-width: 1750px) {
    .rightSide {
        .enenocHeader, .inputUIForm, .infoHeader, .info {
            margin-right: 175px !important;
            margin-left: 175px !important;
        }
    }
}

// if the users screen resizes past a full screen chrome broswer with bookmarks
@media only screen and (min-height: 1000px) {
    .leftSide .copyrightLeftside {
        margin-top: 600px !important;
    }
}