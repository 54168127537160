:root {
    --main: #07243d; 
    --white: whitesmoke;
}

.neighbourPage {
    //margin-top: 200px;
    text-align: center;
    justify-content: center;

    .header {
        //position: absolute;
        //top: 0px;
        height: 150px;
        width: 100%;
        text-align: center;
        background-color: var(--main);
        border-style: solid;
    
        .logo {
            width: 350px;
            margin-top: 1.1em;
        }
    }

    .NeighbourUi {
   
        .matrixNeighbourHeader {
            text-align: left !important;
            font-size: 24px;
            font-style: italic;
            font-weight: normal;
            margin: 40px 25px 25px 25px;
            border-bottom: 2px solid var(--main);
        }

        .notesDiv {
            text-align: left;
            margin: 15px 25px;

            .accordion-header {
            
                button {
                    margin-top: 0;
                    background-color: var(--main);
                    color: var(--white);
                    box-shadow: none;

                    span {
                        color: var(--white);
                    }
                }
            }
        }

        .nocAndSocCodeLabelsDiv {
            margin: 0 25px;
            text-align: left !important;
            white-space: nowrap;

            .nocAndSocCodeLabelsDivTitle {
                margin-bottom: 0.25em;
            }

            .nocCodeLabelDiv {
                // margin-top: -1em;
                width: 250px;
                display: inline-block;
            }
        }

        .overrideValuesDiv, .overrideValuesDiv2 {
            margin: 25px 25px 10px 25px;
            text-align: left !important;

            .overrideValuesDivTitle, .overrideValuesDivTitle2 {
                margin-bottom: 0.5em;
            }

            .overridingAttributeValuesDiv, .overridingAttributeValuesDiv2 {
                display: flex;
                flex-wrap: wrap;
                gap: 0.5em 1em;

                * {
                    display: inline-block;
                }
            }

            .overrideList {
                min-width: 50px !important;
            }
        }

        .attributeLimitDiv {
            margin: 0px 25px 10px 25px;
            text-align: left !important;
        }

        .submitButtonDiv {
            margin: 25px 25px 10px 25px !important;

            * {
                display: flex;
            }
        }

        .attributeLimitDiv {
            margin-top: 25px;
            margin-bottom: -1em;
        }
        
        .missingAttValButton {
            display: inline-block !important;
            margin: 5px 0 0 0 !important;
            width: 5em !important;
            height: 1.5em !important;
            box-shadow: none !important;
        }

        .allAttAndValButton {
            display: inline-block !important;
            margin: 5px 0 0 0 !important;
            width: 5em !important;
            height: 1.5em !important;
            box-shadow: none !important;
        }

        .overrideListUtilityButtons {
            display: inline-block !important;
            margin: 0px !important;
            width: 4em !important;
            height: 1.5em !important;
            box-shadow: none !important;
            // margin-left: 1em !important;
        }

        #overrideListDeleteButton {
            display: inline-block !important;
            margin: 0px !important;
            width: 2.0em !important;
            height: 1.5em !important;
            box-shadow: none !important;
            // margin-left: 1em !important;
            margin-right: 0.5em !important;
        }

        .overrideListDeleteButton {
            color: red;
        }

        // .submitButtonDiv {

        //     * {
        //         display: inline-block !important;
        //     }

        //     .submitButton {
        //         box-shadow: none !important;
        //         // margin: 25px 0px -10px 0px !important;
        //         margin-bottom: -100px !important;
        //         margin-top: 1.5em;
        //     }
        // }

        .submitButtonDiv {
            margin: 25px 0px -5px 0px;
            margin-bottom: -5px;
            width: auto;
            display:flex;
            justify-content:space-between;
            align-items: flex-end;

            button {
                margin: 0px !important;
                min-width: 150px;
            }
        }

        .renderNeighbourTableDiv .react-bootstrap-table {
            // margin: 25px;
            margin: 0 25px;
            display: block;
            height: auto !important;
            min-height: 630px;
            box-shadow: 2px 2px 7px var(--main);
    
            table {
                width: 100%;
                // display: block;
                overflow-x: auto;
                overflow-y: auto;
                white-space: nowrap;
                margin-right: 10px;
                // margin-bottom: 100px;
                table-layout: fixed;
                height: 100%;
            }
            th {
                font-size: 16px;
                text-align: center;
                text-transform: lowercase; 
                position: sticky;
                top: 0; /* Don't forget this, required for the stickiness */
                background-color: var(--main);
                border-color: var(--main);
                // border-style: solid;
                box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
                height: 40px;
                // margin: 0;
                padding-left: 10px;
                padding-right: 10px;
                white-space: normal;
            }
            td {
                border: 1px solid black;
                // white-space: nowrap;
                white-space: normal;
                word-wrap: break-word;
                font-size: 16px;
                text-align: left;
                width: auto;
                min-width: 50px;
                max-width: 1000px;
                height: 50px;
                overflow: auto;
                padding-left: 10px;
                padding-right: 10px;
            }
    
            // div {
            //     display: inline-block;
            // }

            .divForPopup {
                display: block !important;
            }
    
            tbody tr:nth-child(odd) {
                background-color: rgb(220, 220, 220);
            }
    
            // *very important, overrides styling from ResultPage that are picked up from other components
            thead tr {
                border: 0 !important;
            }

            .table tbody tr td:nth-child(1) {
                text-align: center !important;
                width: auto !important;
                max-width: 250px !important;
            }

            .table thead tr th:nth-child(3) {
                text-align: center !important;
                width: auto !important;
                max-width: 150px !important;
            }

            // .table tbody tr td:nth-child(4) {
            //     text-align: center !important;
            // }
    
            // *very important, overrides styling from ResultPage that are picked up from other components
            @media only screen and (max-width: 1050px) {
                .table thead tr th:nth-child(3),
                .table tbody tr td:nth-child(3) {
                    // width: auto !important;
                    text-align: center !important;
                    max-width: 150px !important;
                }
    
                .table thead tr th:nth-child(4),
                .table tbody tr td:nth-child(4) {
                    display: table-cell !important;
                    // text-align: center;
                }
            }

            // *very important, overrides styling from ResultPage that are picked up from other components
            @media only screen and (min-width: 0px) {
                .table thead tr th:nth-child(3),
                .table tbody tr td:nth-child(3) {
                    // width: auto !important;
                    text-align: center !important;
                    max-width: 150px !important;
                }
            }
        }
    
        // pagination under table
        .paginationDiv {
            margin-top: 15px;
    
            span {
                display: inline;
                font-size: 20px;
                color: var(--main);
            }
    
            ul {
                justify-content: center;
    
                li {
                    display: inline;
                    
                    a {
                        background-color: var(--main);
                        color: var(--white)
                    }
                }
            }
        }
    }
}

// styling for MissingAttValPopup.js
.missingAttValDiv {
    position:fixed;
    background: white;
    width: 80%;
    max-width: 500px !important;
    height: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border: 2px solid #07243d;

    * {
        margin-right: 10px;
        margin-left: 10px;
    }
    
    h1 {
        margin-top: 10px;
        font-size: 20px;
        border-bottom: 2px solid var(--main);
    }

    .list {
        overflow: scroll;
        height: 650px;

    }

    button {
        margin: 12.5px auto 0px auto;
        width: 100px;
    }
}
  
.display-block {
    display: block;
}
  
.display-none {
    display: none;
}