/* Overriding some styles in react-bootstrap-table2 css file for our Result Table */
th.selection-cell-header {
    // width: 60px !important;
    width: 80px !important;
    
    p {
        text-align: center !important;
    }
}

th.expand-cell-header {
    width: 180px !important;
    vertical-align: middle !important;
    text-align: center;
    color: var(--white) !important;
}

td.selection-cell {
    text-align: center !important;
}

thead tr {
    background: var(--main) !important;
    border: 3px solid var(--main) !important;
    
    th {
        color: var(--white) !important;
    }
}

.table th {
    vertical-align: middle !important;
}

.table td{
    vertical-align: middle !important;
}

// expansion row popup for job titles
#popupContainer {
    button {
        display: inline;
        margin-left: 10px;
        width: 75px;
    }
}

@media only screen and (max-width: 500px) {
    th.expand-cell-header {
        width: 100px !important; 
        text-align: center;
    }
}

// hides the job column when screen gets too small
@media only screen and (max-width: 1050px) {
    .table thead tr th:nth-child(4),
    .table tbody tr td:nth-child(4) {
        display: none;
    }

    // very important, combines the hidden columns width with this column so that the expand row when clicked is not messed up.
    .table thead tr th:nth-child(3),
    .table tbody tr td:nth-child(3) {
        width: 70% !important;  
    }
}


/* Formats all the buttons unless told otherwise */ 
button {
    margin: 20px auto 0px auto;
    display: block;
    font-size: 18px;
    border-color: var(--main);
    background-color: var(--main);
    color: var(--white);
    text-align: center;
    border: none;
    padding: 0px;
    width: 150px;
    height: 30px;
    // margin-left: 5px;
    transition: all 0.5s;
    cursor: pointer;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
}    

button:hover {
    text-decoration:underline;
}

/* Popup Component */
// handles positioning of actual div on page
.popupDiv {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    // div for content
    .popupInner {
        position: relative;
        padding: 30px;
        width: 90%;
        max-width: 750px;
        background-color: var(--white);

        // content
        h1 {
            font-style: italic;
            width: auto;
            margin: 0px 25px;
            border-bottom: 2px solid var(--main);
        }

        p {
            margin: 20px 25px 0 25px;
        }
            
        .formDiv {
            margin: 20px 25px 0px 25px;
            width: auto;
            // text-align: center;
        
            .inputBox {
                width: 100%;
                height: 150px;
                padding: 10px;
                line-height: 1.25em;
            }

            // need this to overwrite mains font.
            .theButton, span {
                color: var(--white);
            }
        
            .theButton {
                margin: 20px auto 0px auto;
                display: block;

                font-size: 18px;
                border-color: var(--main);
                background-color: var(--main);
                color: var(--white);
                text-align: center;
                border: none;
                padding: 0px;
                width: 150px;
                height: 30px;
                // margin-left: 5px;
                transition: all 0.5s;
                cursor: pointer;
                box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
            }    
        
            .theButton:hover {
                text-decoration:underline;
            }
        }
    }
}

/* Thank you page and 404 page */
.thankyouPage, .notFound {
    background-color: var(--main);
    height: 1000px;
    text-align: center;

    .logo  {
        margin-top: 75px;
    }

    h1 {
        color: var(--white);
        margin: 150px auto 0px auto;
    }

    p {
        color: var(--white);
        margin: 25px auto;
    }

    .returnDiv {
        position: relative;
        margin-top: 100px !important;

        .return {
            width: 125px !important;
            border: 1px solid var(--white);
        }

        .returnLogo{
            width: 24px;
            margin-top: -2px;
            margin-right: 10px;
        }
    }

    @media only screen and (max-width: 550px) {
        .logo {
            max-width: 100% !important;
        }
    }

    @media only screen and (max-width: 350px) {
        h1 {
            font-size: 26px;
        }

        p {
            font-size: 16px;
        }
    }
}

/* Styling for JobListPopup.jsx */
.jobPopup {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}
  
.jobPopupDiv {
    position:fixed;
    background: white;
    width: 80%;
    max-width: 500px !important;
    height: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border: 2px solid #07243d;

    * {
        margin-right: 10px;
        margin-left: 10px;
    }
    
    h1 {
        margin-top: 10px;
        font-size: 20px;
        border-bottom: 2px solid var(--main);
    }

    .list {
        overflow: scroll;
        height: 650px;

    }

    button {
        margin: 12.5px auto 0px auto;
        width: 100px;
    }
}
  
.display-block {
    display: block;
}
  
.display-none {
    display: none;
}


/* Main Styling */

/* Globals */
:root {
    --main: #07243d; 
    --white: whitesmoke;
}

/* all elements */
* {
    // box-sizing: border-box;
    color: var(--main);
    margin: 0;
}

/* Header.js*/
.header {
    //margin: 1em 1em 0em 1em;
    //position: absolute;
    top: 0px;
    height: 150px;
    width: 100%;
    text-align: center;
    background-color: var(--main);
    border-style: solid;

    .logo {
        width: 350px;
        margin-top: 1.1em;
    }
}

/* ResultUI.js, main body of result page */
.resultUI {
    //position: absolute;
    top: 150px;
    bottom: 25px;
    width: 100%;
    background-color: var(--white);
    margin-bottom: 25px;
    // height: 100%;
    
    // return button 
    .returnDiv {
        float: left;
        position: relative;

        .return {
            width: 125px !important;
        }

        .returnLogo{
            width: 24px;
            margin-top: -2px;
            margin-right: 10px;
        }
    }

    // current page links
    .navLinksDiv {
        margin: 0px auto;
        padding: 0;
        box-sizing: border-box;
        font-size: 16px;

        .arrow {
            margin: 0;
        }

        * {
            display: inline-block;
            margin: 5px;
        }

        // can use this for any inactive link
        .show-regular-title, .show-mobile-title {
            pointer-events: none;
            cursor: default;
            text-decoration: none;
            color: black;
        }
    }

    .resultHeader {
        font-size: 24px;
        font-style: italic;
        font-weight: normal;
        // margin: 100px 15px 0px 15px;
        margin: 75px 15px 0px 15px;
        border-bottom: 2px solid var(--main);
    }

    .inputDiv {
        margin: 20px 15px 0px 15px;
    }

    .infoDiv {
        margin: 15px 15px 0px 15px;
        // font-size: 18px;
    }

    .tableDiv {
        margin: 0px 15px;

        // info icon in the description column of table
        .infoButtonDiv {
            text-align: center;
            margin-top: 5px;
            .infoButton {
                border-color: var(--main);
                background-color: none;
                border-style: none;
                width: 35px;
                height: 35px;
            }
        }
    }

    .buttonForm {
        display: block;
        text-align: center;
        margin: 50px auto;
        width: 105px;


        // need this to overwrite mains font.
        .submitButton, span {
            color: var(--white);
        }

        .submitButton {
            font-size: 18px;
            border-color: var(--main);
            background-color: var(--main);
            color: var(--white);
            text-align: center;
            border: none;
            padding: 0px;
            width: 100px;
            height: 30px;
            cursor: pointer;
            box-shadow: 0 10px 15px -8px rgba(0, 0, 0,.7);
        }    

        .submitButton:hover {
            text-decoration:underline;
        }
    }
}


/* Footer.js */
.footerDiv {
    height: 40px;
    margin-top: 160px;
    position: relative;
    background-color: var(--main);
    
    .copyright {
        // color: var(--white);
        font-size: 14px;

        bottom: 0px;
        position: absolute;
        height: 30px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;
        color: var(--white);
        text-align: center;
    }
}




/* Changing specific content depending on webpage width */
// changing navigation link title depending on size
@media only screen and (max-width: 626px) {
    .show-mobile-title {
        display: inline block !important;
    }
    .show-regular-title {
        display: none !important;
    }
}

// changing nav title to fullname when it fits
@media only screen and (min-width: 627px) {
    .show-mobile-title {
        display: none !important;
    }
    .show-regular-title {
        display: inline block !important;
    }
}

// hiding jobtitle column until screen size is appropiate
@media only screen and (max-width: 950px) {
    #jobTitleCol {
        display: none;
    }
}

@media only screen and (min-width: 1250px) {
    .resultHeader, .inputDiv, .infoDiv, .tableDiv {
        margin-right: 50px !important;
        margin-left: 50px !important;
    }
}

@media only screen and (min-width: 1400px) {
    .resultHeader, .inputDiv, .infoDiv, .tableDiv {
        margin-right: 100px !important;
        margin-left: 100px !important;
    }
}

@media only screen and (min-width: 1550px) {
    .resultHeader, .inputDiv, .infoDiv, .tableDiv {
        margin-right: 150px !important;
        margin-left: 150px !important;
    }
}

@media only screen and (min-width: 1700px) {
    .resultHeader, .inputDiv, .infoDiv, .tableDiv {
        margin-right: 200px !important;
        margin-left: 200px !important;
    }
}

@media only screen and (min-width: 1850px) {
    .resultHeader, .inputDiv, .infoDiv, .tableDiv {
        margin-right: 300px !important;
        margin-left: 300px !important;
    }
}

@media only screen and (min-width: 2000px) {
    .resultHeader, .inputDiv, .infoDiv, .tableDiv {
        margin-right: 400px !important;
        margin-left: 400px !important;
    }
}

@media only screen and (min-width: 2150px) {
    .resultHeader, .inputDiv, .infoDiv, .tableDiv {
        margin-right: 500px !important;
        margin-left: 500px !important;
    }
}

@media only screen and (min-width: 2400px) {
    .resultHeader, .inputDiv, .infoDiv, .tableDiv {
        margin-right: 575px !important;
        margin-left: 575px !important;
    }
}

@media only screen and (max-height: 800px) {
    .jobPopupDiv {
        height: 500px !important;

        .list {
            height: 400px;
        }
    }
    
}