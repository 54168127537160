/* Globals */
:root {
    --main: #07243d; 
    --white: whitesmoke;
}

/* AdminPage.js */
.adminPage {
    overflow: hidden;
}


/* LoginPage.js */
.adminLoginPage {
    //margin-top: 200px;
    text-align: center;
    justify-content: center;
    
    .returnDiv {
        float: left;
        position: relative;
        margin-top: -150px;

        .return {
            width: 125px !important;
        }

        .returnLogo{
            width: 24px;
            margin-top: -2px;
            margin-right: 10px;
        }
    }

    h1 {
        // margin: 0;
        margin: 100px auto 10px auto;
        font-size: 32px;
    }
    
    p {
        margin: 0 100px;
    }

    @media only screen and (min-width: 800px) {
        p {
            margin: 0 150px;
        }
    }

    @media only screen and (min-width: 1250px) {
        p {
            margin: 0 350px;
        }
    }
}


/* Header.js*/
.theHeader {
    //margin: 1em 1em 0em 1em;
    // position: absolute;
    top: 0px;
    height: 100px;
    width: 100%;
    text-align: center;
    background-color: var(--main);

    .taxonomyLogo {
        width: 350px;
        margin: 0 auto 0 auto;
    }

    span {
        color: var(--white);
        font-size: 32px;
        display: inline-block;
        margin-top: 0.8em;
        margin-left: 50px;
    }
}


// /* Dashboard.js */
.Dashboard {
    position: absolute;
    top: 100px;
    // bottom: 25px;
    width: 100%;
    background-color: var(--white);
    // height: 100px;
    height: 100%;
    // overflow: hidden;

    margin: 0 auto;
    text-align: center;
    justify-content: center;

    // return button
    .returnDiv {
        float: left;
        position: relative;
        // margin-top: -150px;

        .return {
            width: 125px !important;
        }

        .returnLogo{
            width: 24px;
            margin-top: -2px;
            margin-right: 10px;
        }
    }

    /* Profile.js */
    .profile {
        margin-top: 50px;

        img {
            margin-top: 25px;
            border-radius: 50%;
            width: 125px;
            border: 3px solid var(--main);
        }

        p {
            margin-top: 10px;
            font-size: 20px;
        }
    }


    /* button div */
    .buttonDiv {
        margin: 0 auto;
        width: 90%;

        .refreshDiv, .exportDiv, .signoutDiv, .autocodeDiv, .logoutDiv, .uploadDiv, .deleteDiv {
            width: 150px;
            display: inline-block;
            margin: 0 15px;
        }

        .refreshLogo, .exportLogo, .signoutLogo, .autocodeLogo, .logoutLogo, .uploadLogo, .deleteLogo {
            width: 24px;
            margin-top: -2px;
            margin-right: 10px;
        }
    }

    #showAutoCodeAlert, #showUploadAlert {
        margin: 30px auto 0 auto;
        width: 90%;
        max-width: 600px;
        // margin-bottom: -25px;
    }

    /* Panels.js */
    .panels {
        // display: inline;
        // width: 160px;
        // position: fixed;
        // height: 100%;
        // top: 100px;
        // background-color: var(--main);
        color: var(--white);

        margin: 0 auto;
        display: block;
        position: relative;
        width: 90%;
        max-width: 600px;
        top: 0;
        margin-top: 50px;
        background-color: var(--white);

        h1 {
            font-size: 22px;
            // text-decoration: underline;
            // margin-bottom: 25px;
            // text-align: left;
            // margin-left: 5px;
            // color: var(--white);
            // margin-top: 0px;

            // background-color: var(--main);
            margin: auto auto;
            margin-bottom: 10px;
            color: var(--main);
        }

        #checkboxDiv {
            display: flex;
            justify-content: space-between;
            margin-top: -1em;
        }

        .panelsDiv {
            max-height: 250px;
            overflow-y: scroll;

            .panelButton {
                background-color: var(--main);
                color: var(--white);
                width: 100%;
                // border-style: solid;
                box-shadow: 0 0 0 0;
                // margin-top: 2.5px;
                margin-top: 0;
                margin-bottom: 5px;
                text-align: left;
                text-decoration: none;
    
                border-style: solid;
                border-color: var(--main);
            }
        }
    }

    h2 {
        // margin-left: 175px;
        font-size: 22px;
        // margin-top: 40px;
        margin-left: 0 !important;
        margin-top: 40px; 
    }

    span {
        // margin-left: 175px;
        font-size: 22px;
        // margin-top: 40px;

        margin: 0;
    }

    /* RenderTable.js */
    .renderTableDiv .react-bootstrap-table {
        margin: 0 auto;
        display: block;
        position: relative;
        height: auto !important;
        min-height: 630px;
        width: 90%;
        // margin: 0 auto 500px auto;
        // border-style: solid;
        // border-color: var(--main);
        box-shadow: 2px 2px 7px var(--main);

        table {
            width: 100%;
            display: block;
            overflow-x: auto;
            overflow-y: auto;
            white-space: nowrap;
            margin-right: 10px;
            // margin-bottom: 100px;
            table-layout: fixed;
            height: 100%;
        }
        th {
            font-size: 16px;
            text-align: center;
            text-transform: lowercase; 
            position: sticky;
            top: 0; /* Don't forget this, required for the stickiness */
            background-color: var(--main);
            border-color: var(--main);
            // border-style: solid;
            box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
            height: 40px;
            // margin: 0;
            padding-left: 10px;
            padding-right: 10px;
        }
        td {
            border: 1px solid black;
            // white-space: nowrap;
            // white-space: normal;
            word-wrap: break-word;
            font-size: 16px;
            text-align: left;
            width: auto;
            min-width: 50px;
            max-width: 1000px;
            height: 50px;
            overflow: auto;
            padding-left: 10px;
            padding-right: 10px;
        }

        div {
            display: inline-block;
        }

        tbody tr:nth-child(odd) {
            background-color: rgb(220, 220, 220);
        }

        // need this because table is picking up result table for some reason
        thead tr {
            border: 0 !important;
        }
    }

    // pagination under table
    .paginationDiv {
        margin-top: 15px;

        span {
            display: inline;
            font-size: 20px;
            color: var(--main);
        }

        ul {
            justify-content: center;

            li {
                display: inline;
                
                a {
                    background-color: var(--main);
                    color: var(--white)
                }
            }
        }
    }

    // .row.react-bootstrap-table-pagination {
    //     width: 90%;
    //     margin: 0 auto;
    //     overflow: hidden;
    //     text-align: center;
    //     display: block;
    //     justify-content: right;

    //     .col-md-6.col-xs-6.col-sm-6.col-lg-6 {
    //         // text-align: center;
    //         // justify-content: center;
    //         width: 90%;

    //         span {
    //             font-size: 18px;
    //             // margin-left: 500px;
    //             margin-left: 300px;
    //             float: right;
    //         }
    //     }

    //     ul {
    //         margin-right: 25px !important;
    //         margin-left: 0px;
    //         display: flex;
    //     }
    // }
}


// spacer to make table not sit at bottom
.fillerAdmin {
    // height: 40px;
    margin-top: 25px;
    height: 10px;
    bottom: 0;
    position: relative;
    background-color: var(--white);
}



















// /* AdminPage.js */
// .adminPage {
//     overflow: hidden;
// }

// /* LoginPage.js */
// .adminLoginPage {
//     margin-top: 200px;
//     text-align: center;
//     justify-content: center;
    
//     .returnDiv {
//         float: left;
//         position: relative;
//         margin-top: -100px;

//         .return {
//             width: 125px !important;
//         }

//         .returnLogo{
//             width: 24px;
//             margin-top: -2px;
//             margin-right: 10px;
//         }
//     }

//     h1 {
//         // margin: 0;
//         margin: 250px auto 10px auto;
//         font-size: 32px;
//     }
    
//     .adminLoginForm {
//         border-style: solid;
//         width: 300px;
//         height: 275px;
//         margin: 5px auto;
//         box-shadow: 5px 5px 8px var(--main);

//         .adminLabels {
//             display: inline-block;
//             margin-right: 115px;
//             margin-top: 25px;
//         }
    
//         .adminFields {
//             display: inline-block;
//             width: 200px;
//         }
//     }

//     .loginDiv {

//         .loginButton {
//             width: 200px;
//         }
//     }
// }

// /* Header.js*/
// .theHeader {
//     //margin: 1em 1em 0em 1em;
//     position: absolute;
//     top: 0px;
//     height: 100px;
//     width: 100%;
//     text-align: center;
//     background-color: var(--main);

//     .taxonomyLogo {
//         margin-top: 0.3em;
//         // // margin-right: 150px;
//         // margin-left: 25px;
//         width: 450px;
//         margin: 0.3em auto 0 auto;
//     }

//     span {
//         color: var(--white);
//         font-size: 32px;
//         display: inline-block;
//         margin-top: 0.8em;
//         margin-left: 50px;
//     }
// }


// /* Dashboard.js */
// .Dashboard {
//     position: absolute;
//     top: 100px;
//     bottom: 25px;
//     width: 100%;
//     background-color: var(--white);
//     margin-bottom: 25px;
//     height: 100px;
//     // overflow: hidden;

//     /* button div */
//     .buttonDiv {
//         margin-left: 175px;
//         margin-right: 15px;
//         // justify-content: right;

//         .returnDiv, .refreshDiv, .exportDiv, .signoutDiv, .autocodeDiv {
//             width: 150px;
//             display: inline-block;
//             margin-right: 25px;
//         }

//         .refresh, #export, .return, .signout, .autocode {
//             // margin: 0px;
//             // margin-top: 15px;
//             // margin-left: auto;
//             // margin-right: 15px;
//         }

//         .refreshLogo, .exportLogo, .returnLogo, .signoutLogo, .autocodeLogo {
//             width: 24px;
//             margin-top: -2px;
//             margin-right: 10px;
//         }
//     }
    
//     /* Panels.js */
//     .panels {
//         display: inline;
//         width: 160px;
//         position: fixed;
//         height: 100%;
//         top: 100px;
//         background-color: var(--main);
//         color: var(--white);

//         // .panelsDiv {
//         //     border-style: solid none;
//         // }

//         h1 {
//             font-size: 22px;
//             text-decoration: underline;
//             margin-bottom: 25px;
//             text-align: left;
//             margin-left: 5px;
//             color: var(--white);
//             margin-top: 0px;
//         }

//         .panelButton {
//             background-color: var(--main);
//             color: var(--white);
//             width: 100%;
//             // border-style: solid;
//             box-shadow: 0 0 0 0;
//             // margin-top: 2.5px;
//             margin-top: 0;
//             margin-bottom: 5px;
//             text-align: left;
//             text-decoration: none;
//         }

//         // #testDB {
//         //     border-left: 5px solid whitesmoke;
//         // }
//     }

//     h2 {
//         margin-left: 175px;
//         font-size: 22px;
//         margin-top: 40px;
//     }

//     span {
//         margin-left: 175px;
//         font-size: 22px;
//         margin-top: 40px;
//     }

//     /* RenderTable.js */
//     .renderTableDiv {
//         display: inline;
//         margin-left: 175px;
//         position: fixed;
//         // height: 500px;
//         height: calc(100% - 240px);
//         width: calc(100% - 200px);
//         // border: 2px solid;

//         table {
//             width: 100%;
//             display: block;
//             overflow-x: auto;
//             overflow-y: auto;
//             white-space: nowrap;
//             margin-right: 10px;
//             margin-bottom: 100px;
//             table-layout: fixed;
//             height: 100%;
//             // height: calc(100% - 400px)
//         }
//         th {
//             font-size: 16px;
//             text-align: center;
//             text-transform: lowercase; 
//         }
//         td {
//             border: 1px solid black;
//             white-space: nowrap;
//             font-size: 16px;
//             text-align: left;
//         }
//         div {
//             display: inline-block;
//         }
//     }
// }

// /* Popup Component */
// /* Styling for JobListPopup.jsx */
// .fileUploadPopup {
//     position: fixed;
//     background: rgba(0, 0, 0, 0.5);
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     z-index: 10;

//     .fileUploadPopupDiv {
//         position:fixed;
//         background: white;
//         width: 80%;
//         max-width: 500px !important;
//         height: 80%;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         border: 2px solid #07243d;
    
//         * {
//             margin-right: 25px;
//             margin-left: 25px;
//         }
        
//         h1 {
//             margin-top: 10px;
//             font-size: 20px;
//             border-bottom: 2px solid var(--main);
//         }

//         p {
//             // text-align: center;
//             text-align: left;
//         }

//         // FileDropZone.js
//         .fileDropZone {
//             .fileDropZoneDiv {
                
//             }
//         }
    
//         button {
//             margin: 12.5px auto 0px auto;
//             width: 100px;
//         }
//     }
// }


// // resizing for different screen sizes
// @media only screen and (max-width: 900px) {
//     .renderTableDiv {
//         height: calc(100% - 300px) !important;
//     }
// }

// // resizing for different screen sizes
// @media only screen and (max-width: 550px) {
//     .renderTableDiv {
//         height: calc(100% - 420px) !important;
//     }
    
//     .Dashboard {
//         margin: 0 auto;
//         text-align: center;
//         justify-content: center;

//         .buttonDiv, .panels, .renderTableDiv {
//             margin: 0 auto;
//         }

//         .panels {
//             // display: none !important;
//             display: block;
//             position: relative;
//             width: 90%;
//             top: 0;
//             margin-top: 50px;
//             background-color: var(--white);

//             h1 {
//                 // background-color: var(--main);
//                 margin: 0;
//                 margin-bottom: 10px;
//                 color: var(--main);
//             }

//             .panelButton {
//                 // background-color: var(--white);
//                 // color: var(--main);
//                 border-style: solid;
//                 border-color: var(--main);
//             }
//         }

//         h2 {
//             margin-left: 0 !important;
//             margin-top: 40px; 
//         }

//         span {
//             margin: 0;
//         }

//         .renderTableDiv {
//             border-style: solid;
//             margin: 0;
//             display: block;
//             margin-left: auto;
//             margin-right: auto;
//             position: relative;
//             height: 600px !important;
//             width: 90%;
//         }
//     }
// }

