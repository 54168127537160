:root {
    --main: #07243d; 
    --white: whitesmoke;
}

.matrixPage {
    //margin-top: 200px;
    text-align: center;
    justify-content: center;

    .header {
        //position: absolute;
        top: 0px;
        height: 150px;
        width: 100%;
        text-align: center;
        background-color: var(--main);
        border-style: solid;
    
        .logo {
            width: 350px;
            margin-top: 1.1em;
        }
    }

    // Matrix Lookup
    .matrixLookupHeader {
        text-align: left !important;
        font-size: 24px;
        font-style: italic;
        font-weight: normal;
        margin: 40px 25px 25px 25px;
        border-bottom: 2px solid var(--main);
    }

    .inputForm {
        margin: 0 25px;
        text-align: left !important;

        label {
            margin-bottom: 0px;
        }

        // div that allows the input and button to be on same line
        .inputAndButtonDiv {
            display: flex;
            align-items: baseline;
            margin-top: -2em;

            .inputFieldDiv {
                // margin-top: 2px;
                // display: inline;
                width: 100%;
                max-width: 750px;

                img {
                    position: absolute;
                    margin: 10px 6px;
                    width: 20px;
                    height: 20px;   
                }
    
                input {
                    font-size: 18px;
                    border: 1px solid var(--white);
                    border-color: var(--main);
                    height: 30px;
                    text-indent: 30px;
                    outline: none;
                    margin: 5px 1px 20px 0px;
                    width: 100%;
                }
                
                // input field focus when clicked
                input:focus {
                    box-shadow: 0px 0px 10px var(--main);
                    border: 1.5px solid var(--main);
                }
            }
            
            .buttonDiv {
                // display: inline;
                text-align: center;
                margin-top: 25px;
                margin-left: 0.5em;
    
                // need this to overwrite mains font.
                button, span {
                    color: var(--white);
                }

                button {
                    font-size: 18px;
                    border-color: var(--main);
                    background-color: var(--main);
                    color: var(--white);
                    text-align: center;
                    border: none;
                    padding: 0px;
                    width: 100px;
                    height: 30px;
                    // margin-left: 5px;
                    transition: all 0.5s;
                    cursor: pointer;
                    box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
                }    
                
                /* Styling for button animation and hover symbol */
                button {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    transition: 0.5s;
                }
                  
                button:after {
                    content: '»';
                    position: absolute;
                    opacity: 0;  
                    top: 1px;
                    right: -20px;
                    transition: 0.5s;
                }
                  
                button:hover{
                    padding-right: 20px;
                    padding-left: 10px;
                }
                  
                button:hover:after {
                    opacity: 1;
                    right: 10px;
                }
            }
        }  
    }

    .MatrixJobLookup {

        .searchButtonDiv {
            width: 150px;
            display: inline-block;
            margin: 0 15px;
        }
        
        .searchButtonLogo {
            color: var(--white) !important;
            width: 24px;
            margin-top: -2px;
            margin-right: 10px;
        }
        
        .checkboxDiv {
            margin-top: -1em;
            margin-bottom: 1em;
        }
        
    }

    .utilityDiv {
        margin: 0 25px 5px 25px;
    
        .compareButton {
            margin-right: auto;
            margin-left: 0;
        }
    }

    .renderMatrixTableDiv .react-bootstrap-table {
        margin: 0 25px;
        display: block;
        height: auto !important;
        min-height: 630px;
        box-shadow: 2px 2px 7px var(--main);

        table {
            width: 100%;
            // display: block;
            overflow-x: auto;
            overflow-y: auto;
            white-space: nowrap;
            margin-right: 10px;
            // margin-bottom: 100px;
            table-layout: fixed;
            height: 100%;
        }
        
        th {
            font-size: 16px;
            text-align: center;
            text-transform: lowercase; 
            position: sticky;
            top: 0; /* Don't forget this, required for the stickiness */
            background-color: var(--main);
            border-color: var(--main);
            // border-style: solid;
            box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
            height: 40px;
            // margin: 0;
            padding-left: 10px;
            padding-right: 10px;
        }
        td {
            border: 1px solid black;
            // white-space: nowrap;
            white-space: normal;
            word-wrap: break-word;
            font-size: 16px;
            text-align: left;
            width: auto;
            min-width: 50px;
            max-width: 1000px;
            height: 50px;
            overflow: auto;
            padding-left: 10px;
            padding-right: 10px;
        }

        div {
            display: inline-block;
        }

        tbody tr:nth-child(odd) {
            background-color: rgb(220, 220, 220);
        }

        // *very important, overrides styling from ResultPage that are picked up from other components
        thead tr {
            border: 0 !important;
        }

        .table tbody tr td:nth-child(1) {
            text-align: center
        }

        .table tbody tr td:nth-child(5) {
            text-align: center
        }

        .table tbody tr td:nth-child(6) {
            text-align: center
        }

        // *very important, overrides styling from ResultPage that are picked up from other components
        @media only screen and (max-width: 1050px) {
            .table thead tr th:nth-child(3),
            .table tbody tr td:nth-child(3) {
                width: auto !important;
            }

            .table thead tr th:nth-child(4),
            .table tbody tr td:nth-child(4) {
                display: table-cell !important;
            }
        }
    }

    // pagination under table
    .paginationDiv {
        margin-top: 15px;

        span {
            display: inline;
            font-size: 20px;
            color: var(--main);
        }

        ul {
            justify-content: center;

            li {
                display: inline;
                
                a {
                    background-color: var(--main);
                    color: var(--white)
                }
            }
        }
    }
}